import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
const routes = [
	{
		path: '/',
		redirect: '/customerServiceConsultation',
	},
	{
		path: '/customerServiceConsultation',
		name: '客服咨询',
		component: () => import( /* webpackChunkName: "about" */
			'../views/customerServiceConsultation/customerServiceConsultation.vue')
	},
	// {
	// 	path: '/cpCustomerServiceConsultation',
	// 	name: '代理客服咨询',
	// 	component: () => import( /* webpackChunkName: "about" */
	// 		'../views/cpCustomerServiceConsultation')
	// },
	{
		path: '/response',
		name: '我的',
		component: () => import( /* webpackChunkName: "about" */
			'../views/response')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
