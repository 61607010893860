import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from './plugins/element'

import { parseTime } from '@/api/parseTime.js'

// 解决IOS端 select 选中需点击两下
import FastClick from 'fastclick'
FastClick.attach(document.body)

const app = createApp(App).use(router)
installElementPlus(app)
app.mount('#app')
app.config.globalProperties.parseTime = parseTime;

//引入复制插件
import VueClipboard from 'vue-clipboard3';

import router from './router'
app.use(VueClipboard);
